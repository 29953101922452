<template>
  <div class="error">
    <div class="error__wrapper">
      <div class="error__body">
        <p class="error__title typography__headline3">{{ title }}</p>

        <p class="error__description typography__body2">{{ description }}</p>
      </div>

      <BaseLink
        class="base-button-big base-button-first"
        to="/"
        @click="() => onClickHandler()"
      >
        Перейти на главную
      </BaseLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  description: string;
  title: string;
}

defineProps<Props>();

const onClickHandler = async () => {
  await clearError({
    redirect: "/",
  });
};
</script>

<style scoped>
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: var(--grey-7);
}

.error__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  width: 100%;
  height: 100%;
  padding: 38px;
  text-align: center;
}

@media (--desktop) {
  .error__wrapper {
    row-gap: 40px;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 600px;
    border-radius: 50%;
    background-color: var(--white);
  }
}

.error__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
}

@media (--desktop) {
  .error__title {
    font-weight: 400;
    font-size: 44px;
    line-height: 1.18;
  }
}

@media (--desktop) {
  .error__description {
    font-size: 16px;
  }
}
</style>
