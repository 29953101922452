<template>
  <div
    class="base-input base-input__outer"
    :class="[className, { disabled, error: error && !focused, 'error-counter': hasCounterError, focused }]"
  >
    <div class="base-input__wrapper">
      <slot
        :id="inputId"
        name="label"
        :value="modelValue"
      >
        <label
          v-if="label"
          class="base-input__label"
          :for="inputId"
        >
          {{ label }}
        </label>
      </slot>

      <div class="base-input__inner">
        <slot
          :id="inputId"
          name="prefix"
          :value="modelValue"
        />

        <input
          :id="inputId"
          ref="inputElement"
          v-model="modelValue"
          class="base-input__element"
          :disabled
          v-bind="attrs"
          :name
          :placeholder="placeholder ?? label"
          :type
          @focusin="
            () => {
              onFocusHandler();
              focused = true;
            }
          "
          @focusout="() => (focused = false)"
        />

        <slot
          :id="inputId"
          name="suffix"
          :value="modelValue"
        />
      </div>

      <slot
        :id="inputId"
        name="counter"
        :number-characters
        :value="modelValue"
      >
        <span
          v-if="counter"
          class="base-input__counter"
          :class="[{ error: hasCounterError }]"
        >
          {{ numberCharacters }}/{{ counter }}
        </span>
      </slot>
    </div>

    <div
      v-if="((error || slots.error) && !focused) || hint || slots.hint"
      class="base-input__messages"
    >
      <slot
        v-if="error"
        :id="inputId"
        name="error"
        :value="modelValue"
      >
        <p
          v-if="error && !focused"
          class="base-input__error"
        >
          {{ error }}
        </p>
      </slot>

      <slot
        :id="inputId"
        name="hint"
        :value="modelValue"
      >
        <p
          v-if="hint"
          class="base-input__hint"
        >
          {{ hint }}
        </p>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { InputHTMLAttributes } from "vue";

type Value = null | number | string;

interface Props extends /* @vue-ignore */ InputHTMLAttributes {
  class?: InputHTMLAttributes["class"];
  counter?: string;
  disabled?: boolean;
  error?: boolean | string;
  hint?: string;
  id?: InputHTMLAttributes["id"];
  label?: string;
  name: InputHTMLAttributes["name"];
  placeholder?: InputHTMLAttributes["placeholder"];
  type?: InputHTMLAttributes["type"];
}

const props = withDefaults(defineProps<Props>(), {
  type: "text",
});

const modelValue = defineModel<Value>();

defineSlots<{
  counter: [id: InputHTMLAttributes["id"], numberCharacters: number, value: Value];
  error: [id: InputHTMLAttributes["id"], value: Value];
  hint: [id: InputHTMLAttributes["id"], value: Value];
  label: [id: InputHTMLAttributes["id"], value: Value];
  prefix: [id: InputHTMLAttributes["id"], value: Value];
  suffix: [id: InputHTMLAttributes["id"], value: Value];
}>();

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();
const slots = useSlots();
const { class: className, counter, id, name } = toRefs(props);
const inputElement = ref<HTMLInputElement | null>(null);
const inputId = id.value ?? name.value;
const focused = ref(false);

const numberCharacters = computed(() => {
  return modelValue.value ? String(modelValue.value).length : 0;
});

const hasCounterError = computed(() => {
  return numberCharacters.value > Number(counter.value);
});

// Костыли для телеграма
const onFocusHandler = () => {
  if (inputElement.value === undefined && !document.body.classList.contains("telegram")) {
    return;
  }

  inputElement.value.scrollIntoView({
    behavior: "smooth",
  });
};

onClickOutside(inputElement, () => {});

defineExpose({
  inputElement,
});
</script>

<style scoped>
.base-input__outer {
  display: flex;
  flex-direction: column;
}

.base-input__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.base-input__inner {
  display: flex;
  width: 100%;
}

.base-input__label {
  display: flex;
}

.base-input__element {
  width: 100%;
  margin: 0;
  padding: 0;
  font: inherit;
  letter-spacing: inherit;
  border: none;
  outline: none;
  background-color: transparent;
}

.base-input__element:-webkit-autofill,
.base-input__element:-webkit-autofill:hover,
.base-input__element:-webkit-autofill:focus,
.base-input__element:-webkit-autofill:active {
  background-clip: text;
  box-shadow: none;
}

.base-input__element[type="number"] {
  appearance: textfield;
}

.base-input__element[type="number"]::-webkit-inner-spin-button,
.base-input__element[type="number"]::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

.base-input__element[type="search"]::-webkit-search-decoration,
.base-input__element[type="search"]::-webkit-search-cancel-button,
.base-input__element[type="search"]::-webkit-search-results-button,
.base-input__element[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.base-input__messages {
  display: flex;
  flex-direction: column;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border-radius: 0;
}
</style>
