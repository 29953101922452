<template>
  <Component :is="resolveErrorComponent" />
</template>

<script lang="ts" setup>
import type { NuxtError } from "#app";

const { error } = defineProps<{
  error: NuxtError;
}>();

const resolveErrorComponent = computed(() => {
  switch (error.statusCode) {
    case 403: {
      return resolveComponent("SharedError403");
    }

    case 404: {
      return resolveComponent("SharedError404");
    }

    case 500: {
      return resolveComponent("SharedError500");
    }

    default: {
      return resolveComponent("SharedError500");
    }
  }
});
</script>
